<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
    rounded="sm"
    spinner-variant="primary"
  >
    <b-card>
      <b-card-header
        class="justify-content-start p-0 pb-1 mb-1 border-bottom align-items-center"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="$router.back()"
        >
          <feather-icon
            class="mr-25"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <h3 class="mb-0 ml-2 flex-grow-1">
          {{ title }}
        </h3>
        <h3 class="mb-0">
          <b-badge
            class="font-weight-normal"
            variant="primary"
          >
            {{ subtitle }}
          </b-badge>
        </h3>
      </b-card-header>

      <form-wizard
        :hide-buttons="$route.name === 'startup-event-open-question' && !selectStartup"
        :subtitle="null"
        :title="null"
        back-button-text="Previous"
        class="mb-3"
        color="#7367F0"
        finish-button-text="Submit"
        shape="square"
        @on-complete="updateAnswer"
      >
        <tab-content
          v-for="(section, i) in questions"
          :key="i"
          :title="i"
        >
          <b-row
            v-for="(question, index) in section"
            :key="index"
          >
            <b-col>
              <b-form-group
                class="mb-2"
              >
                <label
                  :class="`h5`"
                  :for="`question-${index}`"
                >
                  &#9654; {{ question.question }} {{ question.is_required ? '*' : '' }}
                  <b-badge variant="light-secondary">{{ question.input_type }}</b-badge>
                </label>
                <template v-slot:description>
                  <small
                    v-if="!readonly && question.input_type === 'Document' && question.programs_applicationresponsetables[0].response "
                    class="font-weight-bold"
                  >File already uploaded. Uploading new file will replace existing file.</small>
                </template>
                <b-input-group class="mt-50">
                  <b-form-textarea
                    v-if="question.input_type === 'Long text'"
                    :id="`answer-${index}`"
                    v-model="question.programs_applicationresponsetables[0].response"
                    :readonly="readonly"
                    placeholder="Your long answer"
                    required
                  />
                  <v-select
                    v-else-if="question.input_type === 'Dropdown' || question.input_type === 'Multiselect'"
                    :id="`answer-${index}`"
                    v-model="question.programs_applicationresponsetables[0].response"
                    :disabled="readonly"
                    :multiple="question.input_type === 'Multiselect'"
                    :options="JSON.parse(question.options)"
                    append-to-body
                    class="w-100"
                    label="Choose from the following"
                    placeholder="Select from list"
                  />
                  <b-form-file
                    v-else-if="readonly !== true && question.input_type==='Document'"
                    :id="`answer-${index}`"
                    v-model="files[question.id]"
                    drop-placeholder="Drop file here..."
                    placeholder="Choose a file or drop it here... [Max: 5MB]"
                    @input="checkFileSize(files[question.id], question.id)"
                  />
                  <b-form-input
                    v-else
                    :id="`answer-${index}`"
                    v-model="question.programs_applicationresponsetables[0].response"
                    :readonly="readonly"
                    :type="question.input_type === 'Number' ? 'number' : 'text'"
                    placeholder="Your short answer"
                    required
                  />
                  <b-input-group-append
                    v-if="question.input_type==='Document' && question.programs_applicationresponsetables[0].response && !files[question.id]"
                  >
                    <Promised
                      :promise="getLink(question.programs_applicationresponsetables[0].response)"
                    >
                      <template v-slot:pending>
                        <b-button
                          class="mr-50"
                          disabled
                          size="sm"
                          variant="primary"
                        >
                          <feather-icon icon="ClockIcon" />
                        </b-button>
                      </template>
                      <template v-slot="data">
                        <b-button
                          v-b-tooltip="'View Uploaded File'"
                          :href="data"
                          class="mr-50"
                          target="_blank"
                          variant="primary"
                        >Open
                        </b-button>
                      </template>
                      <template v-slot:rejected>
                        <span v-b-tooltip="`File not found`">
                          <b-button
                            class="mr-50"
                            disabled
                            size="sm"
                            variant="outline-danger"
                          ><feather-icon icon="AlertTriangleIcon" /></b-button>
                        </span>
                      </template>
                    </Promised>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
      </form-wizard>
    </b-card>
  </b-overlay>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import {
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCol,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { Promised } from 'vue-promised'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BBadge,
    BButton,
    BCard,
    BFormTextarea,
    BFormFile,
    BCardHeader,
    BInputGroup,
    BInputGroupAppend,
    FormWizard,
    TabContent,
    Promised,
  },
  data() {
    return {
      status: '',
      selectStartup: Number.parseInt(this.$route.params.aid, 10),
      currentApplication: '',
      title: '',
      subtitle: 'Event Title',
      incubatorid: '',
      applicationData: [],
      mutationLoading: false,
      files: {},
      associatedOrgDetails: [],
    }
  },
  computed: {
    readonly() {
      return !this.selectStartup || this.status !== 'enabled' || !this.currentApplication || this.$route.query.readonly === 'true'
    },
    userStartups() {
      return this.associatedOrgDetails
        .filter(e => e.role === 'startup')
    },
    questions() {
      this.applicationData.map(el => (el.programs_applicationresponsetables.length === 0
        ? el.programs_applicationresponsetables.push({ response: null }) : el.programs_applicationresponsetables))
      return this.groupByKey(this.applicationData, 'section')
    },
    answers() {
      const arr = []
      this.applicationData.map(r => arr.push({ question_id: r.id, ...r.programs_applicationresponsetables[0] }))
      arr.map(x => {
        Object.assign(x, { response: JSON.stringify(x.response) })
        return x
      })
      return arr
    },
  },
  mounted() {
    if (this.$route.name === 'startup-event-open-question' && !this.$route.params.aid) this.$refs.selectStartupModal.show()
  },
  methods: {
    checkFileSize(file, questionId) {
      if (!file) return false
      const size = 1024 * 1024 * 5
      if (file.size > size) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File size should be less than 5MB',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        this.$nextTick(() => {
          this.files[questionId] = null
        })
        return false
      }
      return true
    },
    getLink(key) {
      return fetch(`https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/programs/files/?file_key=${key}`, {
        method: 'GET',
      }).then(response => response.json())
        .then(data => data.url)
    },
    groupByKey(array, key) {
      return Array.from(array)
        .reduce((hash, obj) => {
          if (obj[key] === undefined) return hash
          return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
        }, {})
    },
    joinIncubator(incubatorId) {
      this.mutationLoading = true
      const mutation = gql`
        mutation {
          insert_users_associationtable_one(
            object: {
              organization_id: ${this.selectStartup},
              incubator_id: ${incubatorId},
              role: "startup",
            }
          ) {
            id
          }
        }
      `
      this.$apollo.mutate({
        mutation,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully joined incubator\'s network.',
              icon: 'CompassIcon',
              variant: 'success',
            },
          })

          this.mutationLoading = false
        })
        .catch(error => {
          if (error.message !== 'GraphQL error: Uniqueness violation. duplicate key value violates unique constraint "users_associationtable_role_incubator_id_organization_id_key"') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                message: error.message,
                icon: 'CompassIcon',
                variant: 'danger',
              },
            })
          }
          this.mutationLoading = false
        })
    },
    async updateAnswer() {
      if (this.readonly) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Submissions are disabled.',
            text: 'If you think this is an error, please contact administrator.',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      }

      this.mutationLoading = true

      // Upload files and get file keys
      const filePromises = Object.entries(this.files).map(async ([k, v]) => {
        const formData = new FormData()
        formData.append('file', v)
        const response = await fetch('https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/programs/files/upload/', {
          method: 'POST',
          body: formData,
        })
        const data = await response.json()
        return [k, data.file_key]
      })

      const fileKeys = await Promise.all(Object.values(filePromises))

      // Replace response with file key
      await this.applicationData.forEach((question, index) => {
        if (question.input_type === 'Document') {
          this.applicationData[index].programs_applicationresponsetables[0].response = Object.fromEntries(fileKeys)[question.id]
        }
      })

      // Update answers
      await this.$apollo.mutate({
        mutation: gql`mutation ($answers: [programs_applicationresponsetable_insert_input!]!) {
          insert_programs_applicantstable_one(object: { organization_id_id: ${this.selectStartup}, current_application_form_id: ${this.$route.params.apid}, program_id: ${this.$route.params.pid}, programs_applicationresponsetables: { data: $answers, on_conflict: {constraint: programs_applicationresponsetable_pkey, update_columns: response}}}, on_conflict: {constraint: programs_applicantstable_program_id_organization_id_id_key, update_columns: [program_id, organization_id_id]}){
            id
          }
        }`,
        variables: {
          answers: this.answers,
        },
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Answers submitted successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.joinIncubator(this.incubatorid)
          this.$apollo.queries.applicationData.refetch()
          this.$router.push({ name: 'startup-portal-events' })
        },
      }).catch(error => {
        this.mutationLoading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to submit answers',
            text: error.message,
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
  apollo: {
    applicationData: {
      query() {
        return gql`
        {
          programs_basicinfo(where: {id: {_eq: ${this.$route.params.pid}}}) {
            id
            title
            organization_id_id
            programs_applicantstables(where:  {organization_id_id: {_eq:${this.selectStartup}}, program_id: {_eq:${this.$route.params.pid}}}) @include(if: ${!!Number.parseInt(this.$route.params.aid, 10)} ) {
                id
                current_application_form_id
              }
            programs_applicationformstables(where: {id: {_eq: ${this.$route.params.apid}}}) {
              status
              round_number
              title
              programs_applicationsquestionstables(order_by: {id: asc}) {
                id
                input_type
                options
                question
                section
                is_required
                programs_applicationresponsetables(where: {programs_applicantstable: { organization_id_id: {_eq:${this.selectStartup || 0}}}}) {
                  id
                  response
                }
              }
            }
          }
        }`
      },
      update(data) {
        if (this.selectStartup) {
          this.currentApplication = !data.programs_basicinfo[0]?.programs_applicantstables[0]?.current_application_form_id || data.programs_basicinfo[0]?.programs_applicantstables[0]?.current_application_form_id === Number.parseInt(this.$route.params.apid, 10)
        }
        const applicationData = data.programs_basicinfo[0]?.programs_applicationformstables[0]
        this.incubatorid = data.programs_basicinfo[0].organization_id_id
        this.status = applicationData?.status
        this.title = applicationData?.title
        this.subtitle = `${data.programs_basicinfo[0]?.title} » Round ${applicationData?.round_number}`
        if (applicationData && applicationData.programs_applicationsquestionstables.length) {
          applicationData.programs_applicationsquestionstables.forEach(q => {
            q.programs_applicationresponsetables.map(a => {
              try {
                JSON.parse(a.response)
              } catch (e) {
                return a
              }
              return Object.assign(a, { response: JSON.parse(a.response) })
            })
          })
        }
        return applicationData?.programs_applicationsquestionstables
      },
    },
    associatedOrgDetails: {
      query() {
        return gql`
           {
                users_associationtable(where: {users_customuser: {email: {_eq: "${getUserData().userEmail}"}}}) {
                  id
                  organization_id
                  program_id
                  role
                  designation
                  users_organizationtable {
                    title
                  }
                }
            }`
      },
      update(data) {
        return data.users_associationtable
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
